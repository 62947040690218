// @see https://github.com/WICG/EventListenerOptions/blob/9dcca95/explainer.md#feature-detection
// @example `elem.addEventListener('touchstart', fn, supportsPassive ? { passive: true } : false);`
function checkSupportPassiveEvents() {
	var supportsPassive = false;
	try {
		var opts = Object.defineProperty({}, 'passive', {
			get: function() {
				supportsPassive = true;
			},
		});
		window.addEventListener('testPassive', null, opts);
		window.removeEventListener('testPassive', null, opts);
	} catch (e) {}

	return supportsPassive;
}

const supportsPassive = checkSupportPassiveEvents();
module.exports = {
	checkSupportPassiveEvents,
	supportsPassive,
	getPassiveOptions() {
		return supportsPassive ? { passive: true } : false;
	},
};
