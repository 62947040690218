export const SELECTED_CANCER_SESSION_COOKIE_NAME = 'keytruda-con-cancer-type';

export const COVID_19_SESSION_COOKIE_NAME = 'keytruda-con-covid-19';

export const CHATBOT_GEOLOCATION_CACHE_KEY = 'con-location';

export const VISITED_COOKIE_KEY_PREFIX = 'con-visit-';

export const CANCER_TYPE_URL_QUERY_PARAM = 'cancer-type';

/**
 * @link https://community.cookiepro.com/s/article/UUID-1e75fb0d-bb79-2af8-cb85-f905d16f1220
 * @todo is hardcoding this cookie name safe? Is there a chance this cookie name could change?
 */
export const ONE_TRUST_COOKIE_BANNER_CLOSED_COOKIE_NAME = 'OptanonAlertBoxClosed';

/**
 * The key stored on the global `window` object to prevent
 * script tags from running twice on IE, due to OneTrust's
 * AutoBlocking script.
 */
export const GLOBAL_SCRIPT_EXECUTION_KEY = '_scriptsExecuted';

export const AUTOMATED_TESTING_HELPERS_KEY = '_AT';
