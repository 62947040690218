export const getInternetExplorerVersion = () => {
	let rv = -1;

	if (navigator.appName === 'Microsoft Internet Explorer') {
		const ua = navigator.userAgent;
		const re = new RegExp('MSIE ([0-9]{1,}[.0-9]{0,})');

		if (re.exec(ua) != null) rv = parseFloat(RegExp.$1);
	} else if (navigator.appName === 'Netscape') {
		const ua = navigator.userAgent;
		const re = new RegExp('Trident/.*rv:([0-9]{1,}[.0-9]{0,})');

		if (re.exec(ua) != null) rv = parseFloat(RegExp.$1);
	}

	return rv;
};

// @see http://browserhacks.com/#hack-2f32c95ac8c021c463de0fdf685acb29
export const isIE = Boolean(window.navigator.msPointerEnabled);
