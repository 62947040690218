import { getPassiveOptions } from './supports-passive-events';

// @see https://github.com/w3c/IntersectionObserver/blob/000ca37/polyfill/intersection-observer.js#L19-L21
const supports_intersection_observer =
	'IntersectionObserver' in window &&
	'IntersectionObserverEntry' in window &&
	'intersectionRatio' in window.IntersectionObserverEntry.prototype;

/**
 * Helper to detect whether or not we have scrolled passed a certain element.
 * On modern browsers, we have the IntersectionObserver API. However, it
 * typically is used when an element is _visible_ within some viewport.
 * If we wanted to use it to collapse our sticky ISI, we run into an issue
 * where the element we are keying off of leaves the viewport _through the top!_
 *
 * That is, say we are keying off the ISI header. We start scrolling, and then
 * then it enters the viewport through the bottom of the screen. Cool, we collapse
 * the sticky ISI. We keep scrolling, and eventually the header leaves the viewport
 * through the top of the screen. The IntersectionObserver then detects that the
 * element is _no longer interesecting!_ But we don't care about this scenario,
 * we only care about when it enters and leaves through the _bottom_ of the screen.
 *
 * This is detectable by looking at the element's `y` coord, but is a bit
 * boilerplately to have to write each time. So, this abstracts out that logic.
 *
 * Additionally, it uses `getBoundingClientRect` and scroll / resize listeners
 * on browsers that don't support IntersectionObserver (e.g. Internet Explorer)
 * rather than using a full blown polyfill.
 *
 * However, the callback's argument won't be a real `IntersectionObserverEntry`
 * in IE and browsers that don't support it. Instead, it'll be an object with
 * a similar shape. It will **only** include `boundingClientRect`, `target`, and
 * `time` properties. All other properties will be `null`, so if your callback
 * uses things like `intersectionRect`, make sure to check for its existance.
 *
 * @example
 *     hasScrolledPassed({
 *       element: document.querySelector('.my-el'),
 *       scrolledPassedFn: (entry) => console.log('We scrolled past!'),
 *       stillBeneathFn: (entry) => console.log('Element is beneath the viewport'),
 *     });
 *
 * @param {Object} opt
 * @param {Element} opt.element - The Element, not the selector.
 * @param {Function} opt.scrolledPassedFn - Requires at least this or `stillBeneathFn` be set. Called with one argument, an `IntersectionObserverEntry`.
 * @param {Function} opt.stillBeneathFn - Requires at least this or `scrolledPassedFn` be set. Called with one argument, an `IntersectionObserverEntry`.
 * @param {Object} [opt.intersection_observer_options]
 * @returns {Function} - Returns a "destroy" method to either disconnect the IntersectionObserver or remove the event listeners.
 */
function hasScrolledPassed({
	element,
	scrolledPassedFn,
	stillBeneathFn,
	intersection_observer_options,
} = {}) {
	if (!element || (!scrolledPassedFn && !stillBeneathFn)) {
		return;
	}

	let previously_scrolled_past = false;
	let first_run = true;

	if (supports_intersection_observer) {
		let observer = new IntersectionObserver((entries) => {
			entries.forEach((entry) => {
				const scrolled_passed =
					entry.isIntersecting ||
					(!entry.isIntersecting && entry.boundingClientRect.y < 0);
				if (first_run) {
					first_run = false;
					if (scrolled_passed) {
						previously_scrolled_past = true;
						scrolledPassedFn?.(entry);
					} else {
						previously_scrolled_past = false;
						stillBeneathFn?.(entry);
					}
				} else if (scrolled_passed && !previously_scrolled_past) {
					previously_scrolled_past = true;
					scrolledPassedFn?.(entry);
				} else if (!scrolled_passed && previously_scrolled_past) {
					previously_scrolled_past = false;
					stillBeneathFn?.(entry);
				}
			});
		}, intersection_observer_options);
		observer.observe(element);

		return () => observer.disconnect();
	} else {
		// Probably Internet Explorer
		const onScrollOrResize = (e) => {
			const element_rect = element?.getBoundingClientRect();
			if (!element_rect) {
				return;
			}

			// @todo support intersection_observer_options.rootMargin
			const scrolled_passed = element_rect.top < window.innerHeight;

			// @todo Make this more resilient
			const fake_entry = {
				boundingClientRect: element_rect,
				intersectionRatio: null,
				intersectionRect: null,
				isIntersecting: null,
				rootBounds: null,
				target: element,
				time: performance?.now?.(),
			};
			if (first_run) {
				first_run = false;
				if (scrolled_passed) {
					previously_scrolled_past = true;
					scrolledPassedFn?.(fake_entry);
				} else {
					previously_scrolled_past = false;
					stillBeneathFn?.(fake_entry);
				}
			} else if (scrolled_passed && !previously_scrolled_past) {
				previously_scrolled_past = true;
				scrolledPassedFn?.(fake_entry);
			} else if (!scrolled_passed && previously_scrolled_past) {
				previously_scrolled_past = false;
				stillBeneathFn?.(fake_entry);
			}
		};
		const passive_options = getPassiveOptions();
		window.addEventListener('scroll', onScrollOrResize, passive_options);
		window.addEventListener('resize', onScrollOrResize, passive_options);

		return () => {
			window.removeEventListener('scroll', onScrollOrResize, passive_options);
			window.removeEventListener('resize', onScrollOrResize, passive_options);
		};
	}
}

export default hasScrolledPassed;
