import URLSearchParams from '@ungap/url-search-params';

/**
 * Create one instance of `URLSearchParams`, to be imported
 * in other libraries and used in whataver ways necessary.
 * If a library needs to create their own instance for some reason,
 * it always can.
 *
 * @link https://developer.mozilla.org/en-US/docs/Web/API/URLSearchParams
 * @example
 *     import query_params from 'lib/util/query-params';
 *     if (query_params.has('some_param')) {
 *         doStuff();
 *     }
 */
const query_params = new URLSearchParams(window.location.search);

export default query_params;
