/**
 * We take our consolidated model data (via our serverside `setLocals` middleware)
 * and write out a selected portion of that to our HTML document via an inline script tag.
 * This data is then exposed to our `window` object under the following key.
 * 
 * The name of this variable is _inspired_ by [redux](https://github.com/reduxjs/redux/blob/16ebb9/docs/recipes/ServerRendering.md)
 * but we can use `__PRELOADED_STATE__` if it seems to be more of a standardized thing.
 */
const PRELOADED_MODEL_VARIABLE = '__PRELOADED_MODEL__';

module.exports = PRELOADED_MODEL_VARIABLE;
