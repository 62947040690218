import once from 'lib/util/once-event-listener.js';
import forceLayoutCalc from 'lib/util/force-layout-calc.js';
import { displayStickyIsi, hideStickyIsi } from '../redux/actions';
import observeStore from '../redux/util/observe-store.js';
import stickyIsiSelector from '../redux/selectors/sticky-isi';
import { isIE } from 'lib/util/internet-explorer.js';
import hasScrolledPassed from './util/has-scrolled-passed.js';
import {
	PRELOADED_MODEL_VARIABLE,
	DISABLE_STICKY_ISI,
} from 'model/global/preloaded-model-keys-to-expose';

export default function initializeStickyIsi() {
	/**
	 * As of now, this element is included in our default template, so
	 * the `sticky` element should always be present. But check for it anyway.
	 */
	const sticky = document.querySelector('.sticky-isi');
	
	if (!sticky || window[PRELOADED_MODEL_VARIABLE][DISABLE_STICKY_ISI]) {
		return;
	}

	const safetyInformationHeading = document.querySelector('.isi__wrapper--inline .isi__heading');

	let transitionEndDestroy = undefined;
	const store = window.store;

	/**
	 * Adjust Sticky ISI's visibility as we update our state.
	 */
	function handleStateUpdates(state, previous_state) {
		if (state?.stickyISI?.visible && !previous_state?.stickyISI?.visible) {
			// Cancel hiding the Sticky ISI if we made it visible while it was transitioning out of view
			transitionEndDestroy?.();

			// Show the Sticky and force it to render
			sticky.classList.remove('sticky-isi--display-none');
			forceLayoutCalc(sticky);

			// Transition the element up
			sticky.classList.add('sticky-isi--visible');
		} else if (!state?.stickyISI?.visible && previous_state?.stickyISI?.visible) {
			// Transition the element down
			sticky.classList.remove('sticky-isi--visible');

			/**
			 * When the element has transitioned down, hide it completely.
			 * We need to save the return value so we can cancel this if
			 * we start to show the sticky in the middle of the transition.
			 */
			transitionEndDestroy = once(sticky, 'transitionend', (e) => {
				sticky.classList.add('sticky-isi--display-none');
			});
		}
	}

	if (safetyInformationHeading) {
		hasScrolledPassed({
			element: safetyInformationHeading,
			scrolledPassedFn: (entry) => store.dispatch(hideStickyIsi()),
			stillBeneathFn: (entry) => store.dispatch(displayStickyIsi()),
		});
	} else {
		// On pages without an inline ISI, we always show the sticky.
		store.dispatch(displayStickyIsi());
	}

	// `position: sticky` IE "polyfill"
	if (isIE) {
		document.body.classList.add('sticky-isi-top-space');
		const footer = document.querySelector('.footer');

		hasScrolledPassed({
			element: footer,
			scrolledPassedFn: (entry) => document.body.classList.remove('sticky-isi-top-space'),
			stillBeneathFn: (entry) => document.body.classList.add('sticky-isi-top-space'),
		});
	}

	observeStore(handleStateUpdates, stickyIsiSelector);
}
