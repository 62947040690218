import { applyMiddleware, createStore } from 'redux';
import rootReducer from './reducers/root-reducer';

// Enhance store with "Redux Devtools" Chrome Extension
// @link https://github.com/zalmoxisus/redux-devtools-extension
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';

// @see https://github.com/zalmoxisus/redux-devtools-extension/blob/2094b4/docs/Features/Trace.md
// @see https://github.com/zalmoxisus/redux-devtools-extension/blob/b3fed3/README.md#13-use-redux-devtools-extension-package-from-npm
const composeEnhancers = composeWithDevTools(
    process.env.NODE_ENV === 'development' ? { trace: true, traceLimit: 25 } : {}
);

// prettier-ignore
const configureStore = preloadedState => {
    return createStore(
        rootReducer,
        preloadedState,
        composeEnhancers(
            applyMiddleware(thunk)
        )
    );
};

export default configureStore;
