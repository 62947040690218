import { AUTOMATED_TESTING_HELPERS_KEY } from 'lib/constants';

function addAutomatedTestingHelperMethod(key, callback) {
	if (!key || typeof callback !== 'function') {
		return;
	}
	key = String(key);

	if (typeof window[AUTOMATED_TESTING_HELPERS_KEY] !== 'object') {
		window[AUTOMATED_TESTING_HELPERS_KEY] = {};
	}

	window[AUTOMATED_TESTING_HELPERS_KEY][key] = callback;
}

export default addAutomatedTestingHelperMethod;
