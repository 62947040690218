import extend from './extend';

/**
 * Toggle the navigation on mobile
 */
class Navigation {
    /**
     *
     * @param {obj} element target element called for initialization
     * @param {obj} options overrides default settings
     */
    constructor(element, options) {
        this.element = element;
        this.options = options;
        this.defaults = {
            mobileNavActiveClassname: 'js-mobile-nav-active', // classname added to body element when nav is active
            mobileNavListClassSelector: '.primary-navigation', // selector to grab toggled element on mobile
            mobileNavParentSelector: '.header', // parent selector
        };

        // combine defaults and options
        this.settings = extend({}, this.defaults, options);

        this.mobileActive = false;

        // bind click handler within constructor, avoids binding to new fn on each bind
        // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_objects/Function/bind#Creating_shortcuts
        this.handleClick = this.toggleNavigationActive.bind(this);
        this.fadeAnimation = this.fadeAnimation.bind(this);
        this.setActiveNav = this.setActiveNav.bind(this)

        // parent element
        this.parentElement = this.element.closest(this.settings.mobileNavParentSelector);

        this.easeOutQuart = this.constructor.easeOutQuart;

        // toggle elements
        this.toggleElements = [
            ...this.parentElement.querySelectorAll(this.settings.mobileNavListClassSelector)
        ];

        this.init();
    }

    /**
     * initializing function
     */
    init() {
        // ** ipad landscape is 1024px so we need to set the minimum width as 1025
        this.mql = window.matchMedia('(min-width: 1025px)');
      
        // if below 1024 (** below 1025 for ipad landscape), add click listener on button
        if (!this.mql.matches) {
            this.element.addEventListener('click', this.handleClick, true);
        }

        // listen for media query changes
        this.mql.addListener(this.handleMediaQueryChange.bind(this));
        
        // handle outside nav clicks
        document.addEventListener('click', event => {
            const isOverlayElement = event.target.classList.contains('overlay');

            // if active and target is outside of header, close
            if (this.mobileActive && isOverlayElement) {
                this.mobileNavActive = false;
            }
        });

        // grab all navigation links
        const selectorLinks = '.primary-navigation__link'
        this.navItems = document.querySelectorAll(selectorLinks)

        this.setActiveNav()
        
    }

    setActiveNav() {
        const navPath = window.location.pathname + window.location.hash
        
        this.navItems.forEach(element => {

            if (element.getAttribute('href') === navPath) {
                element.parentNode.classList.add('active')
            } else {
                element.parentNode.classList.remove('active')
            }
        })
    }


    /**
     * toggle navigation
     */
    toggleNavigationActive() {
        this.mobileNavActive = !this.mobileActive;
        this.parentElement.classList.toggle('active');
    }

    /**
     * add/remove event listener to element.
     * 
     * @param {obj} event media query change event
     */
    handleMediaQueryChange(event) {
        const isLargeScreen = event.matches; // make the event.matches readable

        // remove event listener, styles and active if matched
        if (isLargeScreen) {
            this.element.removeEventListener('click', this.handleClick); // remove event listener
            this.mobileNavActive = false;
            this.removeToggleStyles(); // remove toggle styles
            // remove click event if needed
        } else if (!this.element.onclick) {
            this.element.addEventListener('click', this.handleClick); // add event listener
        }
    }

    /**
     * set mobile active/inactive state
     *
     * @param {bool} isActive bool to add/remove classnames and any other functionality
     */
    set mobileNavActive(isActive) {
        const bodyElement = document.body;

        // set to state
        this.mobileActive = isActive;

        if (!this.mobileActive) { // when closing the nav, update state to match location
            this.setActiveNav()
        }

        // toggle classname
        bodyElement.classList.toggle(this.settings.mobileNavActiveClassname, isActive);
        
        if (!this.mql.matches) this.toggleAnimation();
    }

    toggleAnimation() {
        this.tween = {
            startTime: Date.now(),
            start: 0,
            end: 1,
            duration: 500
        };

        window.requestAnimationFrame(this.fadeAnimation.bind(this));
    }

    fadeAnimation() {
        const {
            tween
        } = this;
        const time = Date.now() - tween.startTime;

        if (time <= tween.duration) {
            const opacity = this.easeOutQuart(time, tween.start, tween.end, tween.duration);
            const appliedOpacity = this.mobileActive ? opacity.toFixed(4) : 1 - opacity.toFixed(4);

            // this.toggleElements.forEach(element => (element.style = `display:block; z-index: 2; opacity: ${appliedOpacity};`));
            this.toggleElements.forEach(element => (element.style = `display:block; z-index: 2;`));

            window.requestAnimationFrame(this.fadeAnimation.bind(this));
        } else {
            this.animationComplete();
        }
    }

    animationComplete() {
        if (!this.mobileActive) this.removeToggleStyles();
    }

    removeToggleStyles() {
        this.toggleElements.forEach(element => element.removeAttribute('style'));
    }

    static easeOutQuart(t, b, c, d) {
        t /= d;
        t--;
        return -c * (t * t * t * t - 1) + b;
    }
}

/**
 * grab all matching elements and initialize a new instance of Navigation Toggle
 *
 * @param {string} selector string passed into querySelectorAll to grab toggle elements
 * @param {obj} options configuration object that overrides defaults
 */
export default (selector, options) => {
    [...document.querySelectorAll(selector)].forEach(
        element => new Navigation(element, options)
    );
};
