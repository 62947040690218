import addAutomatedTestingHelperMethod from 'lib/util/add-at-helper';

export default function initAccordions(heading, _allow_multiple_open = false) {
  let allow_multiple_open = _allow_multiple_open;

  const accordionHeadings = document.querySelectorAll(heading);

  accordionHeadings.forEach((item) => {
    item.addEventListener('click', () => {
      // if (!allow_multiple_open) {
      //   accordionHeadings.forEach((element) => {
      //     if (element !== item) {
      //       element.classList.remove('active');
      //     }
      //   });
      // }

      item.classList.toggle('active');
    });
  });

  addAutomatedTestingHelperMethod('toggleAccordion', (i) => {
    let accordion = accordionHeadings[i];
    accordion?.click?.();
  });

  addAutomatedTestingHelperMethod('openAllAccordions', () => {
    accordionHeadings.forEach((item) => {
      item.classList.add('active');
    });
  });

  addAutomatedTestingHelperMethod('closeAllAccordions', () => {
    accordionHeadings.forEach((item) => {
      item.classList.remove('active');
    });
  });
}
