const PRELOADED_MODEL_VARIABLE = require('./constants/preloaded-model-variable');

const DISABLE_STICKY_ISI = 'disableStickyIsi';

// Flat list of key names to write out in our layout
const modelKeysToExpose = [
	DISABLE_STICKY_ISI,
];

module.exports = {
	PRELOADED_MODEL_VARIABLE,
	modelKeysToExpose,
	DISABLE_STICKY_ISI,
};
