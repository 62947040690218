import createModal from '@designory/yamodal';
import query_params from 'lib/util/query-params';

const initializeModalListeners = () => {
	/**
	 * In an attempt to not include the handlebars runtime
	 * in our JS bundle, I output the modal HTML content in
	 * the document as static HTML, then read the inner HTML
	 * from these DOM nodes and use that giant string for
	 * our modal templates.
	 *
	 * I'm actually doing something a bit fancy and including
	 * these as scripts with a custom `type` attribute so that
	 * the browser won't try to execute it. I can then read
	 * its contents just like I do with any other DOM node!
	 *
	 * Inspired by https://handlebarsjs.com/installation/#usage
	 */
	const conditions_wrapper = document.getElementById('conditions-wrapper');
	const isi_wrapper = document.getElementById('isi-modal-wrapper');
	const isi_safety_overlay_wrapper = document.getElementById('isi-safety-overlay-wrapper');
	const external_modal_wrapper = document.getElementById('external-modal-wrapper');

	if (!conditions_wrapper || !isi_wrapper || !isi_safety_overlay_wrapper ) {
		if (process.env.NODE_ENV === 'development') {
			console.error(
				'Could not find inline modals, these must be in the document for dynamic modals to work. Exiting early.'
			);
		}

		return;
	}

	// Copy the inner HTML
	const conditions_html = conditions_wrapper.innerHTML;
	const isi_html = isi_wrapper.innerHTML;
	const isi_safety_overlay_html = isi_safety_overlay_wrapper.innerHTML;
	const external_modal_html = external_modal_wrapper.innerHTML;

	const conditionsModal = createModal({
		template() {
			return conditions_html;
		},
		beforeInsertIntoDom(modal, trigger_node, event) {
			modal.classList.add('modal--open');
		},
		trigger_selector: `[data-modal-trigger="conditions-modal"]`,
		close_selector: `[data-modal-close]`,
	});

	const isiModal = createModal({
		template() {
			return isi_html;
		},
		beforeInsertIntoDom(modal, trigger_node, event) {
			modal.classList.add('modal--open');
		},
		trigger_selector: `[data-modal-trigger="isi-modal"]`,
		close_selector: `[data-modal-close]`,
	});

	const isiSafetyOverlayModal = createModal({
		template() {
			return isi_safety_overlay_html;
		},
		beforeInsertIntoDom(modal, trigger_node, event) {
			modal.classList.add('modal--open');
		},
		trigger_selector: `[data-modal-trigger="isi-safety-overlay-modal"]`,
		close_selector: `[data-modal-close]`,
	});

	const externalModal = createModal({
		template() {
			return external_modal_html;
		},
		beforeInsertIntoDom(modal, trigger_node, event) {
			modal.classList.add('modal--open');
		},
		trigger_selector: `[data-modal-trigger="external-modal"]`,
		close_selector: `[data-modal-close]`,
	});

	// Opens ISI modal when a query param is present
	if (query_params.has('safety_overlay')) {
		isiSafetyOverlayModal.open();

		// Change 'SCROLL FOR MORE' copy when bottom of modal is reached
		const isi_safety_overlay_copy = document.getElementById('isi-safety-overlay-copy')
		const scroll_text = document.getElementById('safety-overlay-scroll-text')	

		isi_safety_overlay_copy.addEventListener('scroll', function() {
			if (isi_safety_overlay_copy.offsetHeight + isi_safety_overlay_copy.scrollTop >= isi_safety_overlay_copy.scrollHeight) {
				scroll_text.innerHTML = '<a href="/" class="isi-safety-overlay__scroll-text">CONTINUE TO WEBSITE</a>'
			}
		})
	}

	const closeModals = () => {
		conditionsModal.close();
		isiModal.close();
		isiSafetyOverlayModal.close();
		externalModal.close();
	};

	// const listenForCloseMessageFromIframe = function (event) {
	// 	// Message is posted with some `closeModals` truthy value
	// 	if (event.data && event.data.closeModals) {
	// 		closeModals();
	// 	}
	// };

	// window.addEventListener('message', listenForCloseMessageFromIframe, false);

	return {
		conditionsModal,
		isiModal,
		externalModal,
		closeModals,
		removeMessageListener() {
			// window.removeEventListener('message', listenForCloseMessageFromIframe, false);
		},
	};
};

export default initializeModalListeners;
