/**
 * Helper function to subscribe to our redux state, but only run our handler
 * if the state has changed.
 *
 * @example
 *     import observeStore from 'redux/util/observe-store.js';
 *     observeStore(function onChange(currentState, previousState) { ... }, selector);
 *
 * @param {Function} onChange(currentState, previousState) - Callback that runs if the next state is different than current state. Gets passed `currentState` and `previousState` as params.
 * @param {Function} [select] - Optional "selector" function to extract only part of your state. See https://github.com/reduxjs/reselect
 * @param {Object} [store=window.store] - The redux store. Defaults to `window.store`.
 * @returns {Function} - Returns the unsubscribe function for our redux subscription.
 * 
 * @see https://github.com/reduxjs/redux/issues/303#issuecomment-125184409
 */
const observeStore = (onChange, select, store = window.store) => {
    let currentState;

    function handleChange() {
        let nextState = store.getState();
        if (typeof select === 'function') {
            nextState = select(nextState);
        }

        // If you don't use a `select` function, then this will run most times the store updates because we are comparing Objects
        if (nextState !== currentState) {
            const previousState = currentState;
            currentState = nextState;
            onChange(currentState, previousState);
        }
    }

    let unsubscribe = store.subscribe(handleChange);

    // Call one time in init to save value of currentState
    handleChange();

    return unsubscribe;
};

export default observeStore;
