import * as types from '../actions';

const initial_state = {
	visible: false,
};
const stickyISIReducer = (state = initial_state, action) => {
	switch (action.type) {
		case types.displayStickyIsi.type:
			return {
				...state,
				visible: true,
			};

		case types.hideStickyIsi.type:
			return {
				...state,
				visible: false,
			};

		default:
			return state;
	}
};

export default stickyISIReducer;
