/**
 * A utility function to create an action creator for the given action type
 * string. The action creator accepts a single argument, which will be included
 * in the action object as a field called payload. The action creator function
 * will also have its toString() overriden so that it returns the action type,
 * allowing it to be used in reducer logic that is looking for that action type.
 *
 * @see https://github.com/reduxjs/redux-toolkit/blob/f7c5e5a/src/createAction.ts
 *
 * @param {String} type - The action type to use for created actions.
 * @param {Function} [prepare] - An optional method that takes any number of arguments and returns { payload } or { payload, meta }. If this is given, the resulting action creator will pass its arguments to this method to calculate payload & meta.
 * @returns {Function} - Returns the action creator, which can also be used in a reducer's switch statement.
 */
const createAction = (type, prepareAction) => {
	function actionCreator(...args) {
		if (typeof prepareAction === 'function') {
			let prepared = prepareAction(...args);
			if (!prepared) {
				throw new Error('prepareAction did not return an object');
			}

			return {
				type,
				payload: prepared.payload,
				...('meta' in prepared && { meta: prepared.meta }),
				...('error' in prepared && { error: prepared.error }),
			};
		}
		return { type, payload: args[0] };
	}

	actionCreator.toString = () => `${type}`;
	actionCreator.type = type;
	actionCreator.match = (action) => action.type === type;

	return actionCreator;
};

/**
 * @type {Function}
 * @returns {Object}
 */
export const displayStickyIsi = createAction('DISPLAY_STICKY_ISI');

/**
 * @type {Function}
 * @returns {Object}
 */
export const hideStickyIsi = createAction('HIDE_STICKY_ISI');
