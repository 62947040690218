import { GLOBAL_SCRIPT_EXECUTION_KEY } from 'lib/constants';

/**
 * A strange issue first noticed in Keytruda HCP, the OneTrust CCPA
 * Cookie AutoBlock script causes some scripts (it seems like the first
 * tag on the page?) to execute twice on the first visit to a webpage.
 *
 * This helper adds an object to the gloal `window`, and then checks
 * that to see if a callback has been executed before. If it has, it
 * doesn't
 *
 * @param {String} unique_key Any unique key to record whether or not this callback has been executed before.
 * @param {Function} callback
 */
const checkIfCallbackHasRunBeforeExecuting = (unique_key, callback) => {
	if (typeof unique_key !== 'string' && typeof callback !== 'function') {
		if (process.env.NODE_ENV === 'development') {
			console.warn(
				'Incorrect use of "checkIfCallbackHasRunBeforeExecuting", `unique_key` and `callback` are both required.'
			);
		}
		return;
	}

	if (typeof window[GLOBAL_SCRIPT_EXECUTION_KEY] === 'undefined') {
		window[GLOBAL_SCRIPT_EXECUTION_KEY] = {};
	}

	// If the callback hasn't been recorded yet...
	if (!window[GLOBAL_SCRIPT_EXECUTION_KEY][unique_key]) {
		// Save it in our global, then run the callback
		window[GLOBAL_SCRIPT_EXECUTION_KEY][unique_key] = true;
		callback();
	}
};

export default checkIfCallbackHasRunBeforeExecuting;
