// Polyfills for IE
import './polyfills/element.classlist';
import './polyfills/element.closest';
import 'nodelist-foreach-polyfill';
import 'core-js/es/symbol/iterator';
import 'core-js/es/object/assign';
import 'core-js/es/array/find';
import 'core-js/es/array/from';
import 'core-js/es/array/includes';
import 'core-js/es/string/includes';
import 'es6-promise/auto';

import initStickyIsi from './lib/sticky-isi';

import initModal from './lib/modal';
import navigation from './lib/navigation';
import initAccordion from './lib/accordion';
import { AUTOMATED_TESTING_HELPERS_KEY } from './lib/constants';
import configureStore from './redux/configureStore';

import ready from 'lib/util/on-ready';
import checkIfCallbackHasRunBeforeExecuting from 'lib/util/check-if-callback-has-run-before-executing';

if (!window.store) {
	const store = configureStore({
		stickyISI: {
			visible: false,
			expanded: false
		}
	});

	window.store = store;
}

checkIfCallbackHasRunBeforeExecuting('main.js', () => ready(() => {
	if (!window[AUTOMATED_TESTING_HELPERS_KEY]) {
		window[AUTOMATED_TESTING_HELPERS_KEY] = {};
	}

	initStickyIsi();
	navigation('.toggle-mobile-navigation');
	initAccordion('.accordion__title');
	window.modals = initModal();
}));
